<template>
  <div>
    <div
      v-if="isFetching"
      class="global-spinner"
    >
      <div class="spinner">
        <div class="half" />
      </div>
    </div>
    <div v-else>
      <Card>
        <Spin size="large"
              fix
              v-if="isUpdating"
        />
        <span slot="title"> Edit user </span>
        <Row :gutter="40">
          <Col span="16">
            <Form
              class="stacked-form"
              ref="usersEditForm"
              :model="formValidate"
              label-position="left"
              :label-width="200"
            >
              <Divider
                plain
                orientation="left"
              >
                User activity in the last 30 days
              </Divider>
              <br>
              <FormItem label="Latest login">
                <span>{{ getLastLogin }}</span>
              </FormItem>
              <FormItem label="Number of login">
                <span class="chips chips--orange">{{ user && user.logs.numLogins }}</span>
              </FormItem>
              <br>

              <Divider
                plain
                orientation="left"
              >
                User data
              </Divider>
              <FormItem
                prop="status"
                label="Account status"
              >
                <span
                  v-if="formValidate.status"
                  class="chips chips--success"
                >Enabled</span>
                <span
                  v-else
                  class="chips chips--red"
                >Disabled</span>
                <i-switch v-model="formValidate.status" />
              </FormItem>
              <FormItem
                prop="name"
                label="Name"
              >
                <Input
                  type="text"
                  placeholder="name"
                  v-model="formValidate.name"
                />
              </FormItem>
              <FormItem
                prop="email"
                label="@ e-mail"
              >
                <Input
                  type="email"
                  placeholder="Email"
                  v-model="formValidate.email"
                />
              </FormItem>
              <FormItem
                prop="jobTitle"
                label="Job Title"
              >
                <Input
                  type="text"
                  placeholder="Job Title"
                  v-model="formValidate.jobTitle"
                />
              </FormItem>
              <FormItem
                prop="organisation"
                label="Organisation"
              >
                <Input
                  type="text"
                  placeholder="User organisation"
                  v-model="formValidate.organisation"
                />
              </FormItem>
              <FormItem
                prop="roles"
                label="Assigned roles"
              >
                <Select
                  multiple
                  placeholder="Assigned roles"
                  v-model="formValidate.roles"
                >
                  <Option
                    :value="role.id"
                    v-for="role in allRoles.filter(elm => elm.id !== 4)"
                    :key="role.id"
                  >
                    {{ role.name }}
                  </Option>
                </Select>
              </FormItem>


              <!--              WEBSITES ACCESS-->
              <FormItem
                v-if="!userHasSuperAdminRole"
                label="Grant access to websites"
              >
                <Select
                  multiple
                  filterable
                  @on-query-change="searchForProperties"
                  @on-change="handleChangeProperty"
                  placeholder="search/select websites"
                  v-model="formValidate.properties"
                >
                  <Option
                    v-for="property in formValidate.properties_ext"
                    :value="property.id"
                    :key="property.id"
                  >
                    {{ property.displayName }}
                  </Option>
                </Select>
                <Button type="info"
                        class="btn btn--elevated"
                        @click="copyPropertiesToSubscribedProperties"
                >
                  ↓ subscribe to websites weekly reports ↓
                </Button>
              </FormItem>

              <!-- WEEKLY REPORTS ACCESS-->
              <FormItem
                label="Subscribed to websites weekly report"
              >
                <Select
                  multiple
                  filterable
                  @on-query-change="searchForSubscribedProperties"
                  placeholder="search/select websites"
                  v-model="formValidate.subscribed_properties"
                >
                  <Option
                    v-for="property in formValidate.subscribed_properties_ext"
                    :value="property.id"
                    :key="property.id"
                  >
                    {{ property.displayName }}
                  </Option>
                </Select>
              </FormItem>
              <!--              -->
              <!--              <FormItem-->
              <!--                v-else-->
              <!--                label="Websites subscribed for weekly report"-->
              <!--              >-->
              <!--                <Select-->
              <!--                  multiple-->
              <!--                  @on-query-change="searchForProperties"-->
              <!--                  filterable-->
              <!--                  placeholder="Websites"-->
              <!--                  v-model="formValidate.subscribedWeeklyReporPropertiesIds"-->
              <!--                >-->
              <!--                  <Option-->
              <!--                    :value="property.id"-->
              <!--                    v-for="property in allProperties"-->
              <!--                    :key="property.id"-->
              <!--                  >-->
              <!--                    {{ property.displayName }}-->
              <!--                  </Option>-->
              <!--                </Select>-->
              <!--              </FormItem>-->

              <div class="tile-block">
                <div class="form-actions">
                  <Button
                    style="float:right;"
                    type="primary"
                    class="btn btn--elevated"
                    @click="handleSubmit('usersEditForm')"
                  >
                    Update
                  </Button>
                  <Button
                    style="float:right;"
                    @click="handleReset('usersEditForm')"
                    type="text"
                  >
                    Reset
                  </Button>
                  <Button

                    type="warning"
                    class="btn btn--elevated"
                    @click="handleResetPassword"
                  >
                    Send a reset password email
                  </Button>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
      </Card>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import moment from 'moment'

export default {
  name: 'AdminUsersView',
  data () {
    return {
      isUpdating: false,
      isFetching: false,
      availableNotifications: [],
      allProducts: [],
      allRoles: [],
      allProperties: [],
      toogleTree: false,
      user: null,
      apiUser: null,
      formValidate: {
        status: true,
        organisation: '',
        name: '',
        email: '',
        jobTitle: '',
        roles: [],
        properties: [],
        properties_ext: [],
        subscribed_properties: [],
        subscribed_properties_ext: [],
        // subscribedWeeklyReporPropertiesIds: []
      },
      userHasSuperAdminRole : 0
    }
  },
  computed: {
    ...mapGetters({
      'propertyOptions': 'options/properties',
      'hasRole': 'user/hasRole',
      'myUser': 'user/user'
    }),
    getLastLogin () {
      if (this.user && this.user.logs.lastLogin) {
        return moment(this.user.logs.lastLogin, 'YYYYMMDD h:m:')
          .format('YYYY-MM-DD [at] h:m')
      } else {
        return 'None'
      }
    }
  },
  methods: {
    ...mapActions({
      getProperties: 'property/getProperties'
    }),
    searchForProperties (query) {
      if(query.length > 2) {
        return this.getProperties({q: query, limit: 50}).then(({data}) => {
          // what does the dedup + concat does ?
          this.formValidate.properties_ext = this.deduplicateArray(this.formValidate.properties_ext.concat(data))
        })
      }
    },
    searchForSubscribedProperties (query) {
      if(query.length > 2) {
        if (!this.userHasSuperAdminRole) {
          return this.getProperties({q: query, limit: 100}).then( ({data}) => {
            this.formValidate.subscribed_properties_ext = this.deduplicateArray(this.formValidate.subscribed_properties_ext.concat(data.filter(elm => this.formValidate.properties.includes(elm.id))))
          })

        }else{
          return this.getProperties({q: query, limit: 50}).then( ({data}) => {
            // what does the dedup + concat does ?
            this.formValidate.subscribed_properties_ext = this.deduplicateArray(this.formValidate.subscribed_properties_ext.concat(data))
          })
        }
      }
    },
    copyPropertiesToSubscribedProperties (query) {
      this.formValidate.subscribed_properties = this.formValidate.properties
      this.formValidate.subscribed_properties_ext = this.formValidate.properties_ext
    },
    handleChangeProperty () {
      if (!this.userHasSuperAdminRole) {
        this.formValidate.subscribed_properties.forEach((elm, index) => {
          if (!this.formValidate.properties.includes(elm)) {
            this.formValidate.subscribed_properties[index] = null
            // this.$Message.warning('Weekly report subscription automaticaly updated')
          }
        })
        this.formValidate.subscribed_properties = this.formValidate.subscribed_properties.filter(elm => elm !== null)
      }
    },


    async handleResetPassword(){
      try {
        await this.$axios.get('users/forgotPassword', {
          params: {email: this.formValidate.email}
        })
        this.$Notice.open({
          title: this.$t('global.user.watch_box'),
          desc: this.$t('global.user.box_success')
        })
      } catch (e) {
        console.error(e)
      }
    },

    handleReset (name) {
      this.$refs[name].resetFields()
      this.fetch()
    },
    handleSubmit (name) {
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          this.formValidate.name = this.formValidate.name.trim()
          this.isUpdating = true
          const registeredNotifsId = this.availableNotifications.map(notif => notif.children).flat().filter(elm => elm.checked).map(elm => elm.id)
          this.formValidate.notificationtypes = registeredNotifsId
          const payload = {
            ...this.formValidate,
            status: this.formValidate.status ? 'enabled' : 'disabled'
          }
          // keep user admin if he already is
          if (this.hasRole('super-admin') && this.user.id === this.myUser.id) payload.roles.push(4)
          try {
            const responseData = await this.$axios.post(`/users/updatejson/${this.user.id}`, payload)
            this.isUpdating = false
            if (responseData.data.success) {
              this.$Message.success('Changes have been saved!')
              return this.$router.push('/admin/users')
            } else {
              this.$Message.error('An error has occurred...')
            }
          } catch (err) {
            this.isUpdating = false
            this.$Message.error('Server sent back an error...')
            console.error('handleSubmit', err)
          }
        } else {
          this.$Message.error('The form is invalid, please verify it')
        }
      })
    },
    updateActiveNotifications () {

    },
    displayRegisteredNotifs () {
      const { apiUser, availableNotifications } = this
      // const childrens = availableNotifications.map(notif => notif.children).flat(2)
      const registeredNotifsId = apiUser.map(notif => notif.id)
      registeredNotifsId.forEach(id => {
        availableNotifications.forEach(notif => {
          const child = notif.children.find(elm => elm.id === id)
          if (child) {
            child.checked = true
          }
        })
      })
      this.toogleTree = true
    },
    deduplicateArray: function (a) {
      return [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
    },
    async fetch () {
      const {id} = this.$route.params
      console.log("fetch")
      if (id) {
        this.isFetching = true
        try {
          const {data: responseData} = await this.$axios.get(`users/view/${id}?ts=`+ Date.now(), {
            cache: {
              exclude: {query: true}
            },
          })

          if (responseData.success) {
            // decide if we should display the website selector,
            if (Object.values(responseData.widget.data.user.roles).map(elm => elm.id).includes(4)){
              this.userHasSuperAdminRole = 1

            }
            this.user = responseData.widget.data.user

            const responses = await Promise.all([
              this.$axios.get('users/getRoles'),
              //this.getProperties({linkedToUsersIds: [this.user.id], limit: 100}),
              //this.getProperties({limit: 50})
            ])

            const [
              roles,
              propertiesLinked,
              properties
            ] = responses.map(({data}) => {
              return Object.values(data?.widget?.data || data)
            })
            this.allRoles = roles


            //this.allProperties = this.deduplicateArray(propertiesLinked.concat(properties))




            this.formValidate = {
              ...this.user,
              roles: Object.values(this.user.roles)
                .map(x => x.id),
              properties: Object.values(this.user.properties)
                .map(x => x.id),
              properties_ext: Object.values(this.user.properties)
                .map(x => ({
                  id: x.id,
                  displayName: x.displayName,
                })),

              subscribed_properties: Object.values(this.user.subscribed_properties)
                .map(x => parseInt(x.id)),
              subscribed_properties_ext: Object.values(this.user.subscribed_properties)
                .map(x => ({
                  id: parseInt(x.id),
                  displayName: x.displayName,
                })),
              status: this.user.status === 'enabled'
            }
            this.handleChangeProperty()
            // return this.getUsers({params: {onlyRegisteredNotifs: true, id}})
            //   .then(resp => {
            //     this.apiUser = resp.data[0].notificationtypes || []
            //     this.displayRegisteredNotifs()
            //   })
          } else {
            console.log('Server error')
          }
        } catch (err) {
          console.log(err)
        } finally {
          this.isFetching = false
        }
      }
    }
  },
  mounted () {
    this.fetch()
  }
}
</script>
