var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isFetching ? _c('div', {
    staticClass: "global-spinner"
  }, [_vm._m(0)]) : _c('div', [_c('Card', [_vm.isUpdating ? _c('Spin', {
    attrs: {
      "size": "large",
      "fix": ""
    }
  }) : _vm._e(), _c('span', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" Edit user ")]), _c('Row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('Col', {
    attrs: {
      "span": "16"
    }
  }, [_c('Form', {
    ref: "usersEditForm",
    staticClass: "stacked-form",
    attrs: {
      "model": _vm.formValidate,
      "label-position": "left",
      "label-width": 200
    }
  }, [_c('Divider', {
    attrs: {
      "plain": "",
      "orientation": "left"
    }
  }, [_vm._v(" User activity in the last 30 days ")]), _c('br'), _c('FormItem', {
    attrs: {
      "label": "Latest login"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.getLastLogin))])]), _c('FormItem', {
    attrs: {
      "label": "Number of login"
    }
  }, [_c('span', {
    staticClass: "chips chips--orange"
  }, [_vm._v(_vm._s(_vm.user && _vm.user.logs.numLogins))])]), _c('br'), _c('Divider', {
    attrs: {
      "plain": "",
      "orientation": "left"
    }
  }, [_vm._v(" User data ")]), _c('FormItem', {
    attrs: {
      "prop": "status",
      "label": "Account status"
    }
  }, [_vm.formValidate.status ? _c('span', {
    staticClass: "chips chips--success"
  }, [_vm._v("Enabled")]) : _c('span', {
    staticClass: "chips chips--red"
  }, [_vm._v("Disabled")]), _c('i-switch', {
    model: {
      value: _vm.formValidate.status,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate, "status", $$v);
      },
      expression: "formValidate.status"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "name",
      "label": "Name"
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": "name"
    },
    model: {
      value: _vm.formValidate.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate, "name", $$v);
      },
      expression: "formValidate.name"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "email",
      "label": "@ e-mail"
    }
  }, [_c('Input', {
    attrs: {
      "type": "email",
      "placeholder": "Email"
    },
    model: {
      value: _vm.formValidate.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate, "email", $$v);
      },
      expression: "formValidate.email"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "jobTitle",
      "label": "Job Title"
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": "Job Title"
    },
    model: {
      value: _vm.formValidate.jobTitle,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate, "jobTitle", $$v);
      },
      expression: "formValidate.jobTitle"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "organisation",
      "label": "Organisation"
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": "User organisation"
    },
    model: {
      value: _vm.formValidate.organisation,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate, "organisation", $$v);
      },
      expression: "formValidate.organisation"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "roles",
      "label": "Assigned roles"
    }
  }, [_c('Select', {
    attrs: {
      "multiple": "",
      "placeholder": "Assigned roles"
    },
    model: {
      value: _vm.formValidate.roles,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate, "roles", $$v);
      },
      expression: "formValidate.roles"
    }
  }, _vm._l(_vm.allRoles.filter(function (elm) {
    return elm.id !== 4;
  }), function (role) {
    return _c('Option', {
      key: role.id,
      attrs: {
        "value": role.id
      }
    }, [_vm._v(" " + _vm._s(role.name) + " ")]);
  }), 1)], 1), !_vm.userHasSuperAdminRole ? _c('FormItem', {
    attrs: {
      "label": "Grant access to websites"
    }
  }, [_c('Select', {
    attrs: {
      "multiple": "",
      "filterable": "",
      "placeholder": "search/select websites"
    },
    on: {
      "on-query-change": _vm.searchForProperties,
      "on-change": _vm.handleChangeProperty
    },
    model: {
      value: _vm.formValidate.properties,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate, "properties", $$v);
      },
      expression: "formValidate.properties"
    }
  }, _vm._l(_vm.formValidate.properties_ext, function (property) {
    return _c('Option', {
      key: property.id,
      attrs: {
        "value": property.id
      }
    }, [_vm._v(" " + _vm._s(property.displayName) + " ")]);
  }), 1), _c('Button', {
    staticClass: "btn btn--elevated",
    attrs: {
      "type": "info"
    },
    on: {
      "click": _vm.copyPropertiesToSubscribedProperties
    }
  }, [_vm._v(" ↓ subscribe to websites weekly reports ↓ ")])], 1) : _vm._e(), _c('FormItem', {
    attrs: {
      "label": "Subscribed to websites weekly report"
    }
  }, [_c('Select', {
    attrs: {
      "multiple": "",
      "filterable": "",
      "placeholder": "search/select websites"
    },
    on: {
      "on-query-change": _vm.searchForSubscribedProperties
    },
    model: {
      value: _vm.formValidate.subscribed_properties,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate, "subscribed_properties", $$v);
      },
      expression: "formValidate.subscribed_properties"
    }
  }, _vm._l(_vm.formValidate.subscribed_properties_ext, function (property) {
    return _c('Option', {
      key: property.id,
      attrs: {
        "value": property.id
      }
    }, [_vm._v(" " + _vm._s(property.displayName) + " ")]);
  }), 1)], 1), _c('div', {
    staticClass: "tile-block"
  }, [_c('div', {
    staticClass: "form-actions"
  }, [_c('Button', {
    staticClass: "btn btn--elevated",
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.handleSubmit('usersEditForm');
      }
    }
  }, [_vm._v(" Update ")]), _c('Button', {
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "type": "text"
    },
    on: {
      "click": function click($event) {
        return _vm.handleReset('usersEditForm');
      }
    }
  }, [_vm._v(" Reset ")]), _c('Button', {
    staticClass: "btn btn--elevated",
    attrs: {
      "type": "warning"
    },
    on: {
      "click": _vm.handleResetPassword
    }
  }, [_vm._v(" Send a reset password email ")])], 1)])], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "spinner"
  }, [_c('div', {
    staticClass: "half"
  })]);

}]

export { render, staticRenderFns }